/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

.btn-primary {
  background: #74BC20 !important;
  border-color: #74BC20 !important;
  border-radius: 2px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.btn-primary:hover {
  background: #74BC20 !important;
  border-color: #FFF !important;
}

.btn-secondary {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background: var(--orange, #FD8204);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.btn-secondary:hover {
  border: 1px solid var(--Primary, #FD8204) !important;
  background-color: #FD8204 !important;
  color: white !important;
}

.btn-tertiary {
  border-radius: 2px !important;
  border: 1px solid var(--Primary, white) !important;
  background: var(--blanco, #FD8204) !important;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04) !important;
  color: white !important;
}

.btn-tertiary:hover {
  background: #FD8204 !important;
  color: #fff !important;
  border-color: #FD8204 !important;
}

.btn-danger{
  background: #E30001;
  color: #fff;
  border: 1px solid #E30001 !important;
  border-radius: 2px !important;
}

.btn-danger:hover{
  background: #0033A0 !important;
  color: #fff !important;
  border: 1px solid #0033A0 !important;
}

.btn-white{
  border: 1px solid var(--Primary, #D9D9D9) !important;
  background-color: white !important;
  color: black !important;
}

.title-logo {
  color: #000;
  font-size: 18px;
  font-weight: 300;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

aside {
  flex: none !important;
  max-width: 83px !important;
  width: 83px !important;
}

.active {
  color: #74BC20 !important;
}

.profile-title {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.profile-item {
  font-size: 14px;
  font-weight: 400;
}

.search-text {
  color: #636363;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

path.leaflet-interactive:focus {
  outline: none;
}

.filter-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  letter-spacing: -0.32px;
}

.not-information-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.filter-container{
  border: 1px solid #D9D9D9 !important;
  border-radius: 5px !important;
}

.map-container {
  height: 100%;
  width: 100%;
} 

.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color:#0033A0 !important;
  border-color: #0033A0 !important;
}

.ant-input-outlined:hover {
  border-color: #0033A0 !important;
}

.ant-input-outlined:focus {
  border-color:#0033A0 !important;
}

.ant-layout-sider-children {
  position: fixed;
  left: -35px;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #0033A0 !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1uweeqc).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #0033A0 !important;
  box-shadow: 0 0 0 2px rgba(190, 80, 255, 0.1);
  outline: 0;
}


:where(.css-dev-only-do-not-override-1uweeqc).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #FFF;
  font-weight: 600;
  background-color: #be50ff !important;
}



.btn-nav{
  background: #ffffff;
}

.btn-nav:hover{
  color: #74BC20 !important;
  border-color: #74BC20 !important;
  
}

.btn-nav:hover .fontAwesome-color {
  color: #74BC20;
}

.fontAwesome-color {
  color: #6C757D
}


.title-table{
  background: #EFEFEF;
  height: 40px;
  padding: 0px !important;
  margin-left: 110px;
}

.title-table-right{
  background: #EFEFEF;
  height: 40px;
  padding: 0px !important;
  margin-right: 100px;
  border-left: 1px solid #EBEBEB;
}






