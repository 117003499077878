* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

.btn-primary {
  background: #74BC20 !important;
  border-color: #74BC20 !important;
  border-radius: 2px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.btn-primary:hover {
  background: #74BC20 !important;
  border-color: #FFF !important;
}

.btn-secondary {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background: var(--orange, #FD8204);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.btn-secondary:hover {
  border: 1px solid var(--Primary, #FD8204) !important;
  background-color: #FD8204 !important;
  color: white !important;
}

.btn-tertiary {
  border-radius: 2px !important;
  border: 1px solid var(--Primary, white) !important;
  background: var(--blanco, #FD8204) !important;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04) !important;
  color: white !important;
}

.btn-tertiary:hover {
  background: #FD8204 !important;
  color: #fff !important;
  border-color: #FD8204 !important;
}

.btn-danger{
  background: #E30001;
  color: #fff;
  border: 1px solid #E30001 !important;
  border-radius: 2px !important;
}

.btn-danger:hover{
  background: #0033A0 !important;
  color: #fff !important;
  border: 1px solid #0033A0 !important;
}

.btn-white{
  border: 1px solid var(--Primary, #D9D9D9) !important;
  background-color: white !important;
  color: black !important;
}

.title-logo {
  color: #000;
  font-size: 18px;
  font-weight: 300;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

aside {
  flex: none !important;
  max-width: 83px !important;
  width: 83px !important;
}

.active {
  color: #74BC20 !important;
}

.profile-title {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.profile-item {
  font-size: 14px;
  font-weight: 400;
}

.search-text {
  color: #636363;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

path.leaflet-interactive:focus {
  outline: none;
}

.filter-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  letter-spacing: -0.32px;
}

.not-information-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.filter-container{
  border: 1px solid #D9D9D9 !important;
  border-radius: 5px !important;
}

.map-container {
  height: 100%;
  width: 100%;
} 

.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color:#0033A0 !important;
  border-color: #0033A0 !important;
}

.ant-input-outlined:hover {
  border-color: #0033A0 !important;
}

.ant-input-outlined:focus {
  border-color:#0033A0 !important;
}

.ant-layout-sider-children {
  position: fixed;
  left: -35px;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #0033A0 !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1uweeqc).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #0033A0 !important;
  box-shadow: 0 0 0 2px rgba(190, 80, 255, 0.1);
  outline: 0;
}


:where(.css-dev-only-do-not-override-1uweeqc).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #FFF;
  font-weight: 600;
  background-color: #be50ff !important;
}



.btn-nav{
  background: #ffffff;
}

.btn-nav:hover{
  color: #74BC20 !important;
  border-color: #74BC20 !important;
  
}

.btn-nav:hover .fontAwesome-color {
  color: #74BC20;
}

.fontAwesome-color {
  color: #6C757D
}


.title-table{
  background: #EFEFEF;
  height: 40px;
  padding: 0px !important;
  margin-left: 110px;
}

.title-table-right{
  background: #EFEFEF;
  height: 40px;
  padding: 0px !important;
  margin-right: 100px;
  border-left: 1px solid #EBEBEB;
}





